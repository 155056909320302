exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-410-tsx": () => import("./../../../src/pages/410.tsx" /* webpackChunkName: "component---src-pages-410-tsx" */),
  "component---src-templates-blog-index-tsx": () => import("./../../../src/templates/blog/index.tsx" /* webpackChunkName: "component---src-templates-blog-index-tsx" */),
  "component---src-templates-case-index-tsx": () => import("./../../../src/templates/case/index.tsx" /* webpackChunkName: "component---src-templates-case-index-tsx" */),
  "component---src-templates-event-index-tsx": () => import("./../../../src/templates/event/index.tsx" /* webpackChunkName: "component---src-templates-event-index-tsx" */),
  "component---src-templates-news-index-tsx": () => import("./../../../src/templates/news/index.tsx" /* webpackChunkName: "component---src-templates-news-index-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

